
.AppTabBar{
  border-top: 1px solid #f3f3f3;
  padding: 5px 0;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 8;
}
.appTitle{
  letter-spacing: 2px;
  text-indent: 3%;
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0 10px;
}
.apph1{
  letter-spacing: 2px;
  text-indent: 5%;
  font-size: 18px;
  font-weight: 500;
  margin: 30px 0 10px;
}
.Swiper{
  margin: 20px 0;
  border-radius: 5px !important;
}
.Swiper_image{
  width: 95%;
  height: 160px;
  margin: 0 auto;
  border-radius: 5px !important;
}

.orderItem{
  background-color: #fcfcfc !important;
  margin-bottom: 10px;
}

.footer{
  width: 100%;
  color: #bfbfbf;
  text-align: center;
  position: fixed;
  bottom: 100px;
}

/*商品*/
.goodsRow{
  width: 96%;
  margin-left: 2%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.goods_img{
  min-height: 120px;
  width: 100%;
}
.goods_imgA{
  flex: 1;
  height: 100px;
  width: 100px;
  border-radius: 5px;
}
.goods_imgB{
  width: 30%;
  background-color: rgba(0,0,0,0.35);
  position: absolute;
  top: 0px;
  left: 0px;
  /*transform: rotate(-35deg);*/
}
.nanImg{
  position: absolute;
  top: 0;
  left: 0;
  background-color: #bfbfbf;
}
.orderGoodsLi p{
  padding: 0;
  margin: 0;
}

/*商品*/
.HomeGoods{
  display: flex;
}
.homeBar{
  padding: 10px 5px 15px;
}
.homeRow{
  flex: 1;
  overflow: auto;
}
.homeFm{
  width: 96%;
  height: 120px;
  border-radius: 5px;
  margin: 3px 2%;
  background-color: #625d5d;
}
.homeRow::-webkit-scrollbar {
  width: 0;
}
.goods{
  float: left;
  width: 48%;
  margin:5px 1%;
  position: relative;
}
.goodsTitle{
  font-size: 14px;
  letter-spacing: 1px;
  margin: 5px 0;
}
.goods_bar{
  display: flex;
  justify-content: space-between;
}
.goods_bar_t1{
  text-indent: 3px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
}
.goods_bar_t2{
  font-size: 18px;
  font-weight: lighter;
}
.shopping{
  flex: 1;
  display: flex;
  flex-direction: column;
}
.shoppingCat{
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-color: #f5f5f5;
  color: #625d5d;
  position: fixed;
    left: 0;
    bottom: 60px;
}
.shoppingCat p{
  font-size: 16px;
}
.shoppingCat span{
  font-size: 20px;
  font-weight: bold;
}
.shoppingBtn{
  display: flex;
  align-items: center;
}
.card{
  flex: 1;

  background-color: #f5f5f5;
  padding: 10px 0;
}
.cardOrder{
  width: 90%;
  margin-left: 2%;
  margin-bottom: 10px;
}
.orderBtn{
  display: flex;
  justify-content: flex-end;
}
.orderBtn button{
  margin-top: 10px;
  margin-left: 10px;
}
.orderBj{
  background-color: #625d5d;
}
.orderBottom{
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f3f3f3;
}
.orderBottom h3{
  padding: 0;
  margin: 0;
}





.pay{

}
.pay p{
  padding: 0;
  margin: 0;
}
.payBtn{
  flex: 1;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}
.orderCard{
  flex: 1;
  background-color: #f5f5f5;
}
.orderCardItems{
  margin-bottom: 20px;
}
/*校园店*/
.Store{
  /*background-color: #f5f5f5 !important;*/
}
.search{
  padding: 20px 10px 10px;
}
.searchBom{
  padding: 10px;
  background-color: #f5f5f5!important;
}
.storeBody{
  display: flex;
  flex-basis: 0;
  height: 100%;
}
.storeRow{
  flex: 1 1 auto;
  flex-basis: 0;
  position: relative;
  overflow-y: auto;
  display: flex;
}

/*我的页面*/
.My{
  background-color: #fcfcfc;
}
.MyTab{
  margin: 30px auto 15px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 92%;
}
.MyTab_icon{
  margin: 0 20px;
  padding: 15px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 25px;
  font-size: 14px;
  letter-spacing: 2px;
  justify-content: center;
}
.list{
  height: 500px;
  overflow-y: auto;
}
.OrderDeliver{
  display: flex;
  align-items: center;
}
.My_tabs{
  display: flex;
  background-color: #ffffff;
  padding: 15px 0;
  width: 94%;
  margin-left: 3%;
  justify-content: space-between;
  margin-bottom: 20px;
}
.My_tabs_item{
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
.mt3{
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: bold;
}
.mt2{
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: bold;
}
.mt1{
  letter-spacing: 1px;
  margin-top: 1px;
}

/*订单*/
.OrderTitle{
  width: 88vw;
  display: flex;
  justify-content: space-between;
}
.orderT1{
  margin-top: 5px;
  font-size: 15px;
  letter-spacing: 2px;
  color: #525050;
}
.orderT2{
  font-size: 13px;
  color: #bfbfbf;
  letter-spacing: 1px;
}
.shops{
  background-color: #525050;
}
.upGoods{
  display: flex;
  padding: 10px 5px;
  border-bottom: 1px solid #f3f3f3;
  font-size: 13px;
  color: #525050;
}
.upImgArr{
  display: flex;
}
.upImg{
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  background-color: #f5f5f5;
}

.Stepper{
  display: flex;
  width: 90px;
  border-radius: 3px;
  color: #525050;
  border: 1px solid #ff9f18;
  font-weight: bold;
  align-items: center;
  font-size: 14px;
  justify-content: space-around;
}
.Stepper span{
  width: 30px;
  text-align: center;
}
.Stepper .coutnt{
  flex: 1;
  border-left: 1px solid #ff9f18;
  border-right: 1px solid #ff9f18;
}

.justifyContent{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.orderTitle{
  font-size: 16px;
  padding: 5px 10px;
  border-bottom: 1px solid #625d5d;
}

.deliveryBtn{
  position: fixed;
    bottom: 0;
    left: 0;
  width: 100%;
  padding: 10px 0 ;
  display: flex;
  justify-content: center;
}

.addressName{
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #e6e6e6;
  /*padding: 10px;*/
  margin: 0;
  border-radius:50px;
  font-size: 15px;
}

